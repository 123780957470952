import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import TableLoader from "../table-rows/table-loader/TableLoader";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import PropTypes from "prop-types";

const TableWithLabels = (props) => {
  const handleChangePage = (event, newPage) => {
    props.fetchData(newPage, props.rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.fetchData(0, +event.target.value);
  };

  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow sx={{ cursor: "default" }}>
              {props.headCells.map((column) => (
                <TableCell
                  key={"head-cell-" + column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, width: column.width }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!props.loading ? (
              props.tableRows
            ) : (
              <TableLoader
                loading={props.loading}
                colSpan={props.headCells.length}
              />
            )}
          </TableBody>
          {props.showPagination ? (
            <TableFooter>
              <tr>
                {props.count !== 0 ? (
                  <TablePagination
                    rowsPerPageOptions={[5, 15, 25]}
                    count={props.count}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                ) : null}
              </tr>
            </TableFooter>
          ) : null}
        </Table>
      </TableContainer>
    </Paper>
  );
};

TableWithLabels.propTypes = {
  showPagination: PropTypes.bool,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  count: PropTypes.number,
  loading: PropTypes.bool,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      minWidth: PropTypes.number,
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      align: PropTypes.string,
    })
  ).isRequired,
};

TableWithLabels.defaultProps = {
  showPagination: true,
};

export default TableWithLabels;
