import { getAxiosClient } from "../config/axios";

const TemplateApiClient = {
  getTemplateTableData: (identityId) => {
    return getAxiosClient().get(`/api/v1/sts/templates/${identityId}`);
  },
  getTemplateById: (identityId, templateId) => {
    return getAxiosClient().get(
      `/api/v1/sts/templates/${identityId}/templateId/${templateId}`
    );
  },
  getDefaultTemplates: () => {
    return getAxiosClient().get(`/api/v1/sts/templates/defaultTemplates`);
  },
  createTemplate: (requestBody) => {
    return getAxiosClient().post(`/api/v1/sts/templates/create`, requestBody);
  },
  modifyTemplate: (requestBody) => {
    return getAxiosClient().patch(`/api/v1/sts/templates/modify`, requestBody);
  },
  cancelaTemplate: (templateId) => {
    return getAxiosClient().delete(`/api/v1/sts/templates/${templateId}`);
  },
  associateTemplate: (requestBody) => {
    return getAxiosClient().post(
      `/api/v1/sts/templates/associateTemplateToResource`,
      requestBody
    );
  },
  associateTemplateToGroup: (requestBody) => {
    return getAxiosClient().post(
      `/api/v1/sts/templates/associateTemplateToGroup`,
      requestBody
    );
  },
  fetchNumbersAssocia: (requestBody) => {
    return getAxiosClient().post(
      `/api/v1/sts/numbers/currentSearch`,
      requestBody
    );
  },
  fetchGroupsAssocia: (requestBody) => {
    return getAxiosClient().post(
      `/api/v1/sts/groups/currentSearch`,
      requestBody
    );
  },
  exportAssociatedNumbersCsv: (identityId, templateId) => {
    return getAxiosClient().get(
      `/api/v1/sts/templates/${identityId}/templateId/${templateId}/export-csv`,
      {
        responseType: "blob",
      }
    );
  },
  fetchAssociatoLabel: (identityId) => {
    return getAxiosClient().get(
      `/api/v1/sts/templates/${identityId}/associato-label`
    );
  },
};

export default TemplateApiClient;
