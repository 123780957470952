import { useState } from "react";
import InfoBox from "./info-box/InfoBox";
import KeycloakService from "../../../../config/keycloak";
import { AccountCircle, Domain } from "@mui/icons-material";
import ClickableDropdown from "../../../ui/dropdowns/clickable-dropdown/ClickableDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  OPERATOR,
  RESOURCE_ACCESS_PORTALE_GA,
} from "../../../../util/keycloakConstants";
import * as actions from "../../../../store/customers/actions";

const ToolbarInfoBoxes = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedCustomer = useSelector(
    (state) => state.customersReducer.selectedCustomer
  );
  const getCompanyLoading = useSelector(
    (state) => state.customersReducer.customerNameLoading
  );
  const isOperator = KeycloakService.hasResourceRole(
    [OPERATOR],
    RESOURCE_ACCESS_PORTALE_GA
  );
  const initialIntegrationId =
    KeycloakService.getInstance.tokenParsed.companyId;
  const tokenParsed = KeycloakService.getInstance.tokenParsed;
  const dispatch = useDispatch();

  const profileMenuItems = [
    {
      text: "Logout",
      disabled: false,
      icon: null,
      handleClick: () => {
        KeycloakService.doLogout();
      },
    },
  ];

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const iconStyle = {
    color: "black",
    marginRight: "0.5em",
  };

  const handleRemoveSonCustomer = () => {
    const newCompany = {
      codiceFiscale: "",
      customerCode: tokenParsed.customerCode,
      integrationId: tokenParsed.companyId,
      name: tokenParsed.companyId,
      pIva: "",
    };
    dispatch(actions.getCompanyName(tokenParsed.companyId));
    dispatch(actions.setSelectedCompany(newCompany));
  };

  const shouldSwitch =
    !isOperator && selectedCustomer.integrationId !== initialIntegrationId;

  return (
    <>
      <InfoBox
        label={selectedCustomer.name}
        icon={<Domain sx={iconStyle} />}
        onClick={props.handleSetDialogOpen}
        loading={getCompanyLoading}
        shouldSwitch={shouldSwitch}
        handleClickDelete={handleRemoveSonCustomer}
      />
      <InfoBox
        label={KeycloakService.getUsername() ?? ""}
        icon={<AccountCircle sx={iconStyle} />}
        onClick={handleDropdownOpen}
      />
      <ClickableDropdown
        isFromNavbar
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        menuItems={profileMenuItems}
      />
    </>
  );
};

export default ToolbarInfoBoxes;
