import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import { Domain, Search } from "@mui/icons-material";
import CustomersTable from "./customers-table/CustomersTable";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import GenericDialog from "../../../ui/generic-dialog/GenericDialog";
import * as actions from "../../../../store/customers/actions";
import useProgressiveInput from "../../../../util/hooks/useProgressiveInput";
import KeycloakService from "../../../../config/keycloak";

const CustomersDialog = (props) => {
  const selectedCustomer = useSelector(
    (state) => state.customersReducer.selectedCustomer
  );
  const [selected, setSelected] = useState(selectedCustomer);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [isApplicaDisabled, setIsApplicaDisabled] = useState(true);
  const [setTimeoutCustomers, clearTimeoutCustomers] = useProgressiveInput(
    (searchText) => fetchCompaniesProp(0, 5, searchText),
    1000
  );
  const dispatch = useDispatch();
  const loading = useSelector(
    (state) => state.customersReducer.customersList.loading
  );
  const isMasterFather =
    KeycloakService.getInstance.tokenParsed.userType === "REPRESENTATIVE_USER";
  const customerId = KeycloakService.getInstance.tokenParsed.companyId;

  const fetchCompaniesProp = useCallback(
    (page, rowsPerPage, search = "") => {
      setPage(page);
      setRowsPerPage(rowsPerPage);
      dispatch(
        actions.getClientsList(
          page,
          rowsPerPage,
          "",
          search,
          isMasterFather ? customerId : null
        )
      );
    },
    [dispatch, customerId, isMasterFather]
  );

  useEffect(() => {
    fetchCompaniesProp(0, 5);
  }, [fetchCompaniesProp]);

  const handleSearchChange = (e) => {
    const inputText = e.target.value;
    setSearch(inputText);
    setTimeoutCustomers(inputText);
  };

  const handleApplicaClick = () => {
    dispatch(actions.setSelectedCompany(selected));
    props.handleDialogClose();
  };

  const handleSearchKeyPress = (e) => {
    if (e.which === 13) {
      clearTimeoutCustomers();
      fetchCompaniesProp(0, 5, search);
    }
  };

  const handleSearchIconClick = (e) => {
    e.preventDefault();
    clearTimeoutCustomers();
    fetchCompaniesProp(0, 5, search);
  };

  const dialogContent = (
    <>
      {!loading ? (
        <Grid container my={3}>
          <Grid
            container
            alignItems="center"
            justifyContent={{ xs: "center", sm: "flex-start" }}
            item
            xs={12}
            sm={6}
          >
            <Box mr={2}>
              <Domain fontSize={"large"} />
            </Box>
            <Box>
              <Typography variant="subtitle2">
                {selectedCustomer.integrationId}
              </Typography>
              <Typography variant="button">{selectedCustomer.name}</Typography>
            </Box>
          </Grid>
          <Grid
            container
            justifyContent={{ xs: "center", sm: "flex-end" }}
            alignItems="center"
            item
            xs={12}
            sm={6}
          >
            <FormControl sx={{ width: { xs: "100%", sm: "auto" } }}>
              <InputLabel>Search</InputLabel>
              <Input
                value={search}
                onChange={handleSearchChange}
                onKeyUp={handleSearchKeyPress}
                autoFocus
                type="search"
                endAdornment={
                  <InputAdornment
                    position="end"
                    onClick={handleSearchIconClick}
                  >
                    <Search />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      ) : null}

      <CustomersTable
        page={page}
        rowsPerPage={rowsPerPage}
        selectedCustomer={selected}
        setIsApplicaDisabled={setIsApplicaDisabled}
        setSelectedCustomer={setSelected}
        fetchData={(page, rowsPerPage) =>
          fetchCompaniesProp(page, rowsPerPage, search)
        }
      />
    </>
  );

  const dialogActions = (
    <Grid container justifyContent="space-between">
      <Button
        autoFocus
        onClick={props.handleDialogClose}
        color="primary"
        variant="outlined"
      >
        Annulla
      </Button>
      <Button
        autoFocus
        color="primary"
        variant="contained"
        onClick={handleApplicaClick}
        disabled={isApplicaDisabled}
      >
        Applica
      </Button>
    </Grid>
  );

  return (
    <GenericDialog
      dialogWidth="md"
      open={props.open}
      handleDialogClose={props.handleDialogClose}
      handleDialogCloseButton={props.handleDialogClose}
      actions={dialogActions}
      content={dialogContent}
      title={"Seleziona un Cliente"}
    />
  );
};

CustomersDialog.propTypes = {
  handleDialogClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CustomersDialog;
