import { getAxiosClient } from "../config/axios";

const GruppiApiClient = {
  getGruppiTableData: (identityId) => {
    return getAxiosClient().get(`/api/v1/sts/groups/${identityId}`);
  },
  cancelaGruppo: (dtoBody) => {
    return getAxiosClient().post("api/v1/sts/groups/delete", dtoBody);
  },
};

export default GruppiApiClient;
