import { lazy, useMemo } from "react";
import DefaultLayout from "../../layout/default/DefaultLayout";
import { routePaths } from "../../util/constants";
import KeycloakService from "../../config/keycloak";
import { RESOURCE_ACCESS_STS, ROLE_STS } from "../../util/keycloakConstants";
import ContainerLayout from "../../layout/container-layout/ContainerLayout";

const Sts = lazy(() => import("../sts/Sts"));
const NoAccess = lazy(() => import("../no-access/NoAccess"));
const Home = lazy(() => import("../home/Home"));

const useAppRoutes = () => {
  const hasStsRole = KeycloakService.hasResourceRole(
    [ROLE_STS],
    RESOURCE_ACCESS_STS
  );

  const routes = useMemo(() => {
    return [
      {
        path: routePaths.HOME,
        component: Home,
        layout: DefaultLayout,
        canSeeRoute: hasStsRole,
        redirectTo: routePaths.NOT_PERMITTED,
      },
      {
        path: routePaths.STS,
        component: Sts,
        layout: DefaultLayout,
        canSeeRoute: hasStsRole,
        redirectTo: routePaths.NOT_PERMITTED,
      },
      {
        path: routePaths.NOT_PERMITTED,
        component: NoAccess,
        layout: ContainerLayout,
        canSeeRoute: true,
        redirectTo: routePaths.NOT_PERMITTED,
      },
    ];
  }, [hasStsRole]);

  return [routes];
};

export default useAppRoutes;
