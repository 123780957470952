import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { theme } from "../../../theme/theme";

const GenericDialog = (props) => {
  return (
    <>
      <Dialog
        fullWidth
        maxWidth={props.width}
        open={props.open}
        onClose={() => {
          props.handleDialogClose();
        }}
      >
        <IconButton
          onClick={() => {
            props.handleDialogCloseButton();
          }}
          sx={{ alignSelf: "flex-end" }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>{props.content}</DialogContent>
        {props.actions ? (
          <DialogActions
            sx={{
              margin: "0 0 1em 0",
              padding: theme.spacing(2),
            }}
          >
            {props.actions}
          </DialogActions>
        ) : null}
      </Dialog>
    </>
  );
};

GenericDialog.propTypes = {
  width: PropTypes.oneOf(["xl", "md", "sm", "xs", "lg", false]),
  open: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  handleDialogCloseButton: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  content: PropTypes.element.isRequired,
  actions: PropTypes.element,
};

GenericDialog.defaultProps = {
  width: "lg",
};

export default GenericDialog;
