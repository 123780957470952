import * as actionTypes from "./actionTypes";
import { updateObject } from "../../util/updateObject";

const initialState = {
  tags: {
    data: [],
    loading: false,
  },
  associatedTags: {
    data: [],
    loading: false,
  },
  updateTagsListLoading: false,
  unassociatedTagsNumbers: {
    data: [],
    loading: false,
  },
};

const setAssociatedTags = (state, action) => {
  return updateObject(state, {
    associatedTags: updateObject(state.associatedTags, {
      [action.key]: action.value,
    }),
  });
};

const setNotAssociatedTags = (state, action) => {
  return updateObject(state, {
    tags: updateObject(state.tags, {
      [action.key]: action.value,
    }),
  });
};

const createNewTag = (state, action) => {
  return updateObject(state, {
    tags: updateObject(state.tags, {
      data: [...state.tags.data, action.tag],
    }),
  });
};

const changeTagsState = (state, action) => {
  return updateObject(state, {
    tags: updateObject(state.tags, {
      data: action.tagsList,
    }),
  });
};

const updateTagsListLoading = (state, action) => {
  return updateObject(state, { updateTagsListLoading: action.value });
};

const setUnassociatedTagsNumbers = (state, action) => {
  return updateObject(state, {
    unassociatedTagsNumbers: updateObject(state.unassociatedTagsNumbers, {
      [action.key]: action.value,
    }),
  });
};

const tagManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ASSOCIATED_TAGS_FOR_GROUP:
      return setAssociatedTags(state, action);
    case actionTypes.GET_TAGS:
      return setNotAssociatedTags(state, action);
    case actionTypes.CREATE_NEW_TAG:
      return createNewTag(state, action);
    case actionTypes.CHANGE_TAGS_LIST:
      return changeTagsState(state, action);
    case actionTypes.UPDATE_TAGS_LIST_AND_LOADING:
      return updateTagsListLoading(state, action);
    case actionTypes.GET_UNASSOCIATED_TAGS_FOR_NUMBERS:
      return setUnassociatedTagsNumbers(state, action);
    default:
      return state;
  }
};

export default tagManagerReducer;
