export const initialCreateTemplateState = {
  data: {},
  loading: false,
};

export const initialModifyTemplateState = {
  data: {},
  loading: false,
};

export const initialDeleteTemplateState = {
  data: {},
  loading: false,
};

export const initialAssociateTemplateState = {
  data: {},
  loading: false,
};

export const initialAssociateTableDataNumbers = {
  data: {
    numbers: [],
    identityId: "",
  },
  loading: false,
};

export const initialAssociateTableDataGroups = {
  data: {
    groups: [],
    identityId: "",
  },
  loading: false,
};

export const initialTemplateDetailsData = {
  data: {},
  loading: false,
};
