import { useEffect } from "react";
import KeycloakService from "../../config/keycloak";
import useAppRoutes from "./useAppRoutes";
import { Navigate, Route, Routes } from "react-router-dom";
import RouteWrapper from "./route-wrapper/RouteWrapper";
import { routePaths } from "../../util/constants";
import "../../asset/toastify/Toastify.css";
import { useDispatch } from "react-redux";
import * as actions from "../../store/template/actions";
import * as customerActions from "../../store/customers/actions";
import {
  OPERATOR,
  RESOURCE_ACCESS_PORTALE_GA,
} from "../../util/keycloakConstants";

const App = () => {
  const [routes] = useAppRoutes();
  const dispatch = useDispatch();
  const isOperator = KeycloakService.hasResourceRole(
    [OPERATOR],
    RESOURCE_ACCESS_PORTALE_GA
  );

  useEffect(() => {
    console.log(KeycloakService.getToken());
    console.log(KeycloakService.getInstance.tokenParsed);

    if (!isOperator) {
      const tokenParsed = KeycloakService.getInstance.tokenParsed;
      const newCompany = {
        codiceFiscale: "",
        customerCode: tokenParsed.customerCode,
        integrationId: tokenParsed.companyId,
        name: tokenParsed.companyId,
        pIva: "",
      };
      dispatch(customerActions.getCompanyName(tokenParsed.companyId));
      dispatch(customerActions.setSelectedCompany(newCompany));
    }

    dispatch(actions.getDefaultTemplates());
  }, [dispatch, isOperator]);

  return (
    <Routes>
      {routes.map((r) => (
        <Route
          path={r.path}
          key={r.path}
          element={
            <RouteWrapper
              component={r.component}
              layout={r.layout}
              canSeeRoute={r.canSeeRoute}
              redirectTo={r.redirectTo}
            />
          }
        />
      ))}
      <Route path="*" element={<Navigate to={routePaths.HOME} />} />
    </Routes>
  );
};

export default App;
