export const sediInitialState = {
  data: {
    sediListVoip: [],
    sediListWlr: [],
    sediListStandard: [],
  },
  loading: false,
};

export const sediDetailsInitialState = {
  data: {
    idSede: "",
    indirizzo: "",
    codiceAlias: "",
    associatedTemplates: "",
    numbers: [],
    //TODO: Add tags if needed
  },
  loading: false,
};
