import Navigation from "../../component/navigation/Navigation";
import React from "react";
import {Box} from "@mui/material";

const DefaultLayout = ({children}) => {

    return (
        <Navigation>
            <Box component='main'
                 sx={{
                     mt: {
                         xs: '57px',
                         sm: '80px'
                     },
                     ml: {
                         xs: '0',
                         sm: '240px'
                     }
                 }}
                 padding='2em'>
                {children}
            </Box>
        </Navigation>
    )
}

export default DefaultLayout;
