import { getAxiosClient } from "../config/axios";

const NumeriApiClient = {
  getNumeriTableData: (identityId) => {
    return getAxiosClient().get(`/api/v1/sts/numbers/${identityId}`);
  },
  createNewGroup: (requestBody) => {
    return getAxiosClient().post(`/api/v1/sts/groups/create`, requestBody);
  },
  getNumbersForGroup: (identityId, tecnologia, groupId) => {
    return getAxiosClient().get(
      `/api/v1/sts/numbers/${identityId}/tecnologia/${tecnologia}/groupId/${groupId}`
    );
  },
  modifyGroup: (requestBody) => {
    return getAxiosClient().post(`/api/v1/sts/groups/modify`, requestBody);
  },
  fetchNumeriDetails: (identityId, numberId) => {
    return getAxiosClient().get(
      `/api/v1/sts/numbers/${identityId}/numberId/${numberId}`
    );
  },
  modifyNumber: (identityId, numberId) => {
    return getAxiosClient().post(
      `/api/v1/sts/numbers/${identityId}/numberId/${numberId}`
    );
  },
  fetchTagsForNumbers: (requestBody) => {
    return getAxiosClient().post(
      `/api/v1/sts/numbers/tagsForNumbers`,
      requestBody
    );
  },
  addTagsToNumbers: (requestBody) => {
    return getAxiosClient().post(
      `/api/v1/sts/numbers/associateTags`,
      requestBody
    );
  },
  exportCsv: (requestBody) => {
    return getAxiosClient().post(
      `/api/v1/sts/numbers/export-csv`,
      requestBody,
      { responseType: "blob" }
    );
  },
};

export default NumeriApiClient;
