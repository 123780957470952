import axios from 'axios';
import KeycloakService from "./keycloak";

export const getAxiosClient = () => {
  const kc = KeycloakService.getInstance

  const getBaseUrl = () => {
    const host = window.location.hostname
    if (host.includes('localhost'))
      return 'http://localhost:8080'

    return 'https://' + host + ':8443'
  }

  const client = axios.create({
    baseURL: getBaseUrl(),
    responseType: "json",
    headers: {
      "Content-Type": "application/json"
    }
  });

  client.interceptors.request.use(async (config) => {
    if (kc.isTokenExpired()) {
      try {
        await kc.updateToken();
      } catch (e) {
        kc.logout();
      }
    }

    config.headers.Authorization = `Bearer ${kc.token}`;
    return config;
  });

  client.interceptors.response.use(
      response => {
        if (response.headers['content-type'] && response.headers['content-type'].includes('text/html')) {
          return Promise.reject({response: {data: 'HTML returned from web server'}})
        }
        return response
      },
      error => {
        if (error?.response?.status === 401) {
          kc.logout()
        }
        return Promise.reject(error);
      }
  );

  return client;
}


