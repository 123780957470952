import * as actionTypes from "./actionTypes";
import { updateObject } from "../../util/updateObject";
import {
  initialAssociateTableDataGroups,
  initialAssociateTableDataNumbers,
  initialAssociateTemplateState,
  initialCreateTemplateState,
  initialDeleteTemplateState,
  initialModifyTemplateState,
  initialTemplateDetailsData,
} from "./helper";

const initialState = {
  data: {
    voip: [],
    standard: [],
    wlr: [],
    voipDefault: null,
    standardDefault: null,
    wlrDefault: null,
  },
  loading: false,
  createTemplate: initialCreateTemplateState,
  modifyTemplate: initialModifyTemplateState,
  deleteTemplate: initialDeleteTemplateState,
  associateTemplate: initialAssociateTemplateState,
  associateTableDataNumbers: initialAssociateTableDataNumbers,
  associateTableDataGroups: initialAssociateTableDataGroups,
  templateDetails: initialTemplateDetailsData,
  exportAssociatedNumbersLoading: false,
  associatoLabelLoading: false,
};

const updateTemplateData = (state, action) => {
  return updateObject(state, {
    [action.key]: updateObject(state.data, { ...action.value }),
  });
};

const setLoading = (state, action) => {
  return updateObject(state, {
    [action.key]: action.value,
  });
};

const setExportAssociatedNumbersLoading = (state, action) => {
  return updateObject(state, {
    exportAssociatedNumbersLoading: action.value,
  });
};

const setCreateTemplateStatus = (state, action) => {
  return updateObject(state, {
    createTemplate: updateObject(state.createTemplate, {
      [action.key]: action.value,
    }),
  });
};

const setModifyTemplateStatus = (state, action) => {
  return updateObject(state, {
    modifyTemplate: updateObject(state.modifyTemplate, {
      [action.key]: action.value,
    }),
  });
};

const setDeleteTemplateStatus = (state, action) => {
  return updateObject(state, {
    deleteTemplate: updateObject(state.deleteTemplate, {
      [action.key]: action.value,
    }),
  });
};

const setAssociateTemplateStatus = (state, action) => {
  return updateObject(state, {
    associateTemplate: updateObject(state.associateTemplate, {
      [action.key]: action.value,
    }),
  });
};

const setAssociateTableDataNumbers = (state, action) => {
  return updateObject(state, {
    associateTableDataNumbers: updateObject(state.associateTableDataNumbers, {
      [action.key]: action.value,
    }),
  });
};

const setAssociateTableDataGroups = (state, action) => {
  return updateObject(state, {
    associateTableDataGroups: updateObject(state.associateTableDataGroups, {
      [action.key]: action.value,
    }),
  });
};

const updateTemplateDetails = (state, action) => {
  return updateObject(state, {
    templateDetails: updateObject(state.templateDetails, {
      [action.key]: action.value,
    }),
  });
};

const setEmptyOutTemplateState = (state, action) => {
  return updateObject(state, {
    [action.key]: action.value,
  });
};

const setAssociatoLabelLoading = (state, action) => {
  return updateObject(state, {
    associatoLabelLoading: action.value,
  });
};

const setAssociatoLabelData = (state, action) => {
  return updateObject(state, {
    data: updateObject(state.data, {
      voip: action.voip,
      wlr: action.wlr,
      standard: action.standard,
    }),
  });
};

const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TEMPLATE_DATA_AND_DEFAULT:
      return updateTemplateData(state, action);
    case actionTypes.GET_TEMPLATES_LOADING:
      return setLoading(state, action);
    case actionTypes.CREATE_TEMPLATE:
      return setCreateTemplateStatus(state, action);
    case actionTypes.MODIFY_TEMPLATE:
      return setModifyTemplateStatus(state, action);
    case actionTypes.DELETE_TEMPLATE:
      return setDeleteTemplateStatus(state, action);
    case actionTypes.ASSOCIATE_TEMPLATE:
      return setAssociateTemplateStatus(state, action);
    case actionTypes.ASSOCIA_TABLE_DATA_NUMBERS:
      return setAssociateTableDataNumbers(state, action);
    case actionTypes.ASSOCIA_TABLE_DATA_GROUPS:
      return setAssociateTableDataGroups(state, action);
    case actionTypes.GET_TEMPLATE_BY_ID:
      return updateTemplateDetails(state, action);
    case actionTypes.EMPTY_OUT_TEMPLATE_STATE:
      return setEmptyOutTemplateState(state, action);
    case actionTypes.EXPORT_ASSOCIATED_NUMBERS_LOADING:
      return setExportAssociatedNumbersLoading(state, action);
    case actionTypes.ASSOCIATO_LABEL_LOADING:
      return setAssociatoLabelLoading(state, action);
    case actionTypes.ASSOCIATO_LABEL_DATA:
      return setAssociatoLabelData(state, action);
    default:
      return state;
  }
};

export default templateReducer;
