export const isLightColor = (color) => {
  const hex = color.replace("#", "");
  const c_r = parseInt(hex.substr(0, 2), 16);
  const c_g = parseInt(hex.substr(2, 2), 16);
  const c_b = parseInt(hex.substr(4, 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
  return brightness > 175;
};

export const formatFilterHistoryWithRadio = (radioButtons, filter) => {
  return `${radioButtons.find((rb) => rb.value === filter.radioValue).text}: ${
    filter.cerca
  }`;
};

export const getDateWithoutTime = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  }
  return "";
};

export const formatMissingData = (strValue) => {
  if (!strValue?.toString().trim()) {
    return "-";
  }
  return strValue.toString();
};

export const mapToCsvData = (headCells, rows) => {
  let mappedRows = [["ID", ...headCells.map((hc) => hc.label)]];
  rows.forEach((row) => {
    mappedRows = [...mappedRows, Object.values(row)];
  });
  return mappedRows;
};

export const getSortingObject = (sortBy) => {
  let sortField = null;
  let sortOrder = null;
  if (!!sortBy) {
    const sortParts = sortBy.split(",");
    sortField = sortParts[0];
    sortOrder = sortParts[1].toUpperCase;
  }
  return { sortField, sortOrder };
};

export const changeNullValuesToStrings = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) obj[key] = "";
  });
  return obj;
};
