import ReactDOM from "react-dom/client";
import App from "./component/app/App";
import reportWebVitals from "./reportWebVitals";
import KeycloakService from "./config/keycloak";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./theme/theme";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { ToastContainer } from "react-toastify";
import { ToastCloseButton } from "./component/shared/toast/ToastCloseButton";

const root = ReactDOM.createRoot(document.getElementById("root"));
const renderApp = () =>
  root.render(
    <Provider store={store}>
      <ToastContainer
        autoClose={4000}
        hideProgressBar
        closeButton={ToastCloseButton}
      />
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );

//renderApp();
KeycloakService.initKeycloak(renderApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
