import PropTypes from "prop-types";
import ToolbarInfoBoxes from "./toolbar-info-boxes/ToolbarInfoBoxes";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import CustomersDialog from "./customers-dialog/CustomersDialog";
import KeycloakService from "../../../config/keycloak";
import {
  OPERATOR,
  RESOURCE_ACCESS_PORTALE_GA,
} from "../../../util/keycloakConstants";

const CustomToolbar = (props) => {
  const [customersDialogOpen, setCustomersDialogOpen] = useState(false);
  const theme = useTheme();
  const drawerWidth = 240;
  const canOpenCustomerList = KeycloakService.hasResourceRole(
    [OPERATOR],
    RESOURCE_ACCESS_PORTALE_GA
  );
  const isMasterFather =
    KeycloakService.getInstance.tokenParsed.userType === "REPRESENTATIVE_USER";

  const isOperator = window.location.hostname
    .toLowerCase()
    .includes("operator");

  const handleOpenCustomersDialog = () => {
    if (canOpenCustomerList || isOperator || isMasterFather) {
      setCustomersDialogOpen(true);
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        bgcolor: theme.palette.common.white,
      }}
    >
      <Toolbar
        sx={{
          minHeight: { sm: "80px" },
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={props.handleSidebarToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          noWrap
          sx={{ flexGrow: 1 }}
          color={theme.palette.common.black}
        >
          Medie e grandi imprese - Fisso
        </Typography>
        <ToolbarInfoBoxes handleSetDialogOpen={handleOpenCustomersDialog} />
      </Toolbar>
      {customersDialogOpen ? (
        <CustomersDialog
          handleDialogClose={() => setCustomersDialogOpen(false)}
          open={customersDialogOpen}
        />
      ) : null}
    </AppBar>
  );
};

CustomToolbar.propTypes = {
  handleSidebarToggle: PropTypes.func.isRequired,
};

export default CustomToolbar;
