export const GET_TEMPLATE_DATA_AND_DEFAULT = "GET_TEMPLATE_DATA_AND_DEFAULT";
export const GET_TEMPLATES_LOADING = "GET_TEMPLATES_LOADING";
export const GET_TEMPLATE_BY_ID = "GET_TEMPLATE_BY_ID";
export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const MODIFY_TEMPLATE = "MODIFY_TEMPLATE";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const ASSOCIATE_TEMPLATE = "ASSOCIATE_TEMPLATE";
export const ASSOCIA_TABLE_DATA_NUMBERS = "ASSOCIA_TABLE_DATA_NUMBERS";
export const ASSOCIA_TABLE_DATA_GROUPS = "ASSOCIA_TABLE_DATA_GROUPS";
export const EMPTY_OUT_TEMPLATE_STATE = "EMPTY_OUT_TEMPLATE_STATE";
export const EXPORT_ASSOCIATED_NUMBERS_LOADING =
  "EXPORT_ASSOCIATED_NUMBERS_LOADING";
export const ASSOCIATO_LABEL_LOADING = "ASSOCIATO_LABEL_LOADING";
export const ASSOCIATO_LABEL_DATA = "ASSOCIATO_LABEL_DATA";
