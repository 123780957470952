import PropTypes from "prop-types";
import {
  Box,
  CircularProgress,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { HighlightOff } from "@mui/icons-material";

const InfoBox = (props) => {
  const theme = useTheme();
  const boxComponent = (
    <Box
      alignItems="center"
      ml="1.5em"
      display="inline-flex"
      color={theme.palette.common.black}
      sx={{ cursor: "pointer" }}
      onClick={!props.shouldSwitch ? props.onClick : undefined}
    >
      {props.icon}

      <Box
        borderRadius="0.5em"
        bgcolor={props.shouldSwitch ? "#7C92A5" : "white"}
        border={props.shouldSwitch ? "none" : "2px solid rgba(0,0,0,0.2)"}
        color={props.shouldSwitch ? "white" : "black"}
        padding="8px 48px"
        display="flex"
        justifyContent="space-between"
      >
        {!props.loading ? (
          <>
            <Typography
              variant="button"
              color="inherit"
              maxWidth="150px"
              display="block"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {props.label}
            </Typography>
            {props.shouldSwitch ? (
              <HighlightOff
                color={"inherit"}
                onClick={props.handleClickDelete}
                sx={{ ml: "0.2em" }}
              />
            ) : null}
          </>
        ) : (
          <CircularProgress size={24} />
        )}
      </Box>
    </Box>
  );
  return (
    <Tooltip title={props.label} arrow>
      {boxComponent}
    </Tooltip>
  );
};

InfoBox.propTypes = {
  loading: PropTypes.bool.isRequired,
  shouldSwitch: PropTypes.bool,
  handleClickDelete: PropTypes.func,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element.isRequired,
};

InfoBox.defaultProps = {
  loading: false,
};

export default InfoBox;
