import { updateObject } from "../../util/updateObject";
import * as actionTypes from "./actionTypes";
import {
  initialCreateGroupState,
  initialNumeriDetails,
  initialTagsForNumbers,
} from "./helper";

const initialState = {
  data: {
    voip: [],
    standard: [],
    wlr: [],
  },
  loading: false,
  createNewGroup: initialCreateGroupState,
  numeriDetails: initialNumeriDetails,
  modifyNumberLoading: false,
  tagsForNumbers: initialTagsForNumbers,
  addTagsToNumbersLoading: false,
  csvLoading: false,
};

const setNumeriTablesData = (state, action) => {
  return updateObject(state, {
    [action.key]: action.value,
  });
};

const setCreateGroupStatus = (state, action) => {
  return updateObject(state, {
    createNewGroup: updateObject(state.createNewGroup, {
      [action.key]: action.value,
    }),
  });
};

const setNumeriDetails = (state, action) => {
  return updateObject(state, {
    numeriDetails: updateObject(state.numeriDetails, {
      [action.key]: action.value,
    }),
  });
};

const setModifyNumberLoading = (state, action) => {
  return updateObject(state, {
    modifyNumberLoading: action.value,
  });
};

const setTagsForNumbersDataAndLoading = (state, action) => {
  return updateObject(state, {
    tagsForNumbers: updateObject(state.tagsForNumbers, {
      [action.key]: action.value,
    }),
  });
};

const setAddTagsToNumbersLoading = (state, action) => {
  return updateObject(state, {
    addTagsToNumbersLoading: action.value,
  });
};

const setExportCsvLoading = (state, action) => {
  return updateObject(state, {
    csvLoading: action.value,
  });
};

const numeriReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NUMERI_DATA_AND_LOADING:
      return setNumeriTablesData(state, action);
    case actionTypes.CREATE_NEW_GROUP:
      return setCreateGroupStatus(state, action);
    case actionTypes.GET_NUMERI_DETAILS:
      return setNumeriDetails(state, action);
    case actionTypes.MODIFY_NUMBER:
      return setModifyNumberLoading(state, action);
    case actionTypes.GET_TAGS_FOR_NUMBERS:
      return setTagsForNumbersDataAndLoading(state, action);
    case actionTypes.ADD_TAGS_TO_NUMBERS:
      return setAddTagsToNumbersLoading(state, action);
    case actionTypes.EXPORT_NUMERI_LOADING:
      return setExportCsvLoading(state, action);
    default:
      return state;
  }
};

export default numeriReducer;
