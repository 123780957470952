import TemplateApiClient from "../../api/templateApiClient";
import * as actionTypes from "./actionTypes";
import { toastError, toastSuccess } from "../../component/shared/toast/Toast";
import { store } from "../store";
import fileSaver from "file-saver";

export const getTemplateTableData = (identityId, shouldCallAssociatoLabel) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_TEMPLATES_LOADING,
      value: true,
      key: "loading",
    });
    TemplateApiClient.getTemplateTableData(identityId)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_TEMPLATE_DATA_AND_DEFAULT,
          value: res.data,
          key: "data",
        });
        if (shouldCallAssociatoLabel) {
          dispatch(getAssociatoLabel(identityId));
        }
      })
      .catch((e) => console.error(e.response.data))
      .finally(() => {
        dispatch({
          type: actionTypes.GET_TEMPLATES_LOADING,
          value: false,
          key: "loading",
        });
      });
  };
};

export const emptyTemplateTableData = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_TEMPLATE_DATA_AND_DEFAULT,
      value: {
        voip: [],
        wlr: [],
        standard: [],
      },
      key: "data",
    });
  };
};

export const getTemplateById = (identityId, templateId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_TEMPLATE_BY_ID,
      value: true,
      key: "loading",
    });
    TemplateApiClient.getTemplateById(identityId, templateId)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_TEMPLATE_BY_ID,
          value: res.data.result,
          key: "data",
        });
      })
      .catch((e) => console.error(e.response.data))
      .finally(() => {
        dispatch({
          type: actionTypes.GET_TEMPLATE_BY_ID,
          value: false,
          key: "loading",
        });
      });
  };
};

export const getDefaultTemplates = () => {
  return (dispatch) => {
    TemplateApiClient.getDefaultTemplates()
      .then((res) => {
        dispatch({
          type: actionTypes.GET_TEMPLATE_DATA_AND_DEFAULT,
          value: res.data,
          key: "data",
        });
      })
      .catch((e) => console.error(e.response.data))
      .finally(() => {});
  };
};

export const createTemplate = (requestBody) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_TEMPLATE,
      value: true,
      key: "loading",
    });
    TemplateApiClient.createTemplate(requestBody)
      .then((res) => {
        dispatch({
          type: actionTypes.CREATE_TEMPLATE,
          value: res.data,
          key: "data",
        });
        if (res.data.statusType !== "OK") {
          toastError("Si è verificato un errore.");
        }
        dispatch(getTemplateTableData(requestBody.identityId, true));
      })
      .catch((e) => {
        toastError("Operazione fallita.");
        console.error(e.response.data);
      })
      .finally(() => {
        dispatch({
          type: actionTypes.CREATE_TEMPLATE,
          value: false,
          key: "loading",
        });
      });
  };
};

export const modifyTemplate = (requestBody) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.MODIFY_TEMPLATE,
      value: true,
      key: "loading",
    });
    TemplateApiClient.modifyTemplate(requestBody)
      .then((res) => {
        dispatch({
          type: actionTypes.MODIFY_TEMPLATE,
          value: res.data,
          key: "data",
        });
        if (res.data.statusType !== "OK") {
          toastError("Si è verificato un errore.");
        }
        dispatch(getTemplateTableData(requestBody.identityId, true));
      })
      .catch((e) => {
        toastError("Operazione fallita.");
        console.error(e.response.data);
      })
      .finally(() => {
        dispatch({
          type: actionTypes.MODIFY_TEMPLATE,
          value: false,
          key: "loading",
        });
      });
  };
};

export const cancelaTemplate = (identityId, templateId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_TEMPLATE,
      value: true,
      key: "loading",
    });
    TemplateApiClient.cancelaTemplate(templateId)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_TEMPLATE,
          value: res.data,
          key: "data",
        });
        if (res.data.statusType !== "OK") {
          toastError("Operazione fallita.");
        } else {
          toastSuccess("Template eliminato con successo.");
          dispatch(getTemplateTableData(identityId, true));
        }
      })
      .catch((e) => {
        console.log(e.response.data);
        toastError(e.response.data.message);
      })
      .finally(() => {
        dispatch({
          type: actionTypes.DELETE_TEMPLATE,
          value: false,
          key: "loading",
        });
      });
  };
};

export const associateTemplate = (requestBody) => {
  const integrationId =
    store.getState().customersReducer.selectedCustomer.integrationId;
  return (dispatch) => {
    dispatch({
      type: actionTypes.ASSOCIATE_TEMPLATE,
      value: true,
      key: "loading",
    });
    TemplateApiClient.associateTemplate(requestBody)
      .then((res) => {
        dispatch({
          type: actionTypes.ASSOCIATE_TEMPLATE,
          value: res.data,
          key: "data",
        });
        if (res.data.statusType !== "OK") {
          toastError(res.data.result);
        }
        dispatch(getTemplateTableData(integrationId, true));
      })
      .catch((e) => {
        toastError(
          e.response.data.result
            ? e.response.data.result
            : "Operazione fallita."
        );
        dispatch(getTemplateTableData(integrationId, true));
        console.error(e.response.data);
      })
      .finally(() => {
        dispatch({
          type: actionTypes.ASSOCIATE_TEMPLATE,
          value: false,
          key: "loading",
        });
      });
  };
};

export const associateTemplateToGroup = (requestBody) => {
  const integrationId =
    store.getState().customersReducer.selectedCustomer.integrationId;
  return (dispatch) => {
    dispatch({
      type: actionTypes.ASSOCIATE_TEMPLATE,
      value: true,
      key: "loading",
    });
    TemplateApiClient.associateTemplateToGroup(requestBody)
      .then((res) => {
        dispatch({
          type: actionTypes.ASSOCIATE_TEMPLATE,
          value: res.data,
          key: "data",
        });
        if (res.data.statusType !== "OK") {
          toastError(
            "Si è verificato un errore durante la associato del template."
          );
        }
        dispatch(getTemplateTableData(integrationId, true));
      })
      .catch((e) => {
        toastError(
          "La risorsa e' gia' appartenente ad un numero. " +
            "Non e' possibile settare una configurazione ad una risorsa con tali caratteristiche."
        );
        console.error(e.response.data);
      })
      .finally(() => {
        dispatch({
          type: actionTypes.ASSOCIATE_TEMPLATE,
          value: false,
          key: "loading",
        });
      });
  };
};

export const getNumbersForAssocia = (requestBody) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ASSOCIA_TABLE_DATA_NUMBERS,
      value: true,
      key: "loading",
    });
    TemplateApiClient.fetchNumbersAssocia(requestBody)
      .then((res) => {
        dispatch({
          type: actionTypes.ASSOCIA_TABLE_DATA_NUMBERS,
          value: res.data.result,
          key: "data",
        });
      })
      .catch((e) => {
        console.error(e.response.data);
      })
      .finally(() => {
        dispatch({
          type: actionTypes.ASSOCIA_TABLE_DATA_NUMBERS,
          value: false,
          key: "loading",
        });
      });
  };
};

export const getGroupsForAssocia = (requestBody) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ASSOCIA_TABLE_DATA_GROUPS,
      value: true,
      key: "loading",
    });
    TemplateApiClient.fetchGroupsAssocia(requestBody)
      .then((res) => {
        dispatch({
          type: actionTypes.ASSOCIA_TABLE_DATA_GROUPS,
          value: res.data.result,
          key: "data",
        });
      })
      .catch((e) => {
        console.error(e.response.data);
      })
      .finally(() => {
        dispatch({
          type: actionTypes.ASSOCIA_TABLE_DATA_GROUPS,
          value: false,
          key: "loading",
        });
      });
  };
};

export const emptyAssociaFilterTableNumbers = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ASSOCIA_TABLE_DATA_NUMBERS,
      value: { numbers: [], identityId: "" },
      key: "data",
    });
  };
};

export const emptyAssociaFilterTableGroups = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ASSOCIA_TABLE_DATA_GROUPS,
      value: { groups: [], identityId: "" },
      key: "data",
    });
  };
};

export const setDefaultTemplateDetails = (tableType) => {
  const defaultTemplate =
    store.getState().templateReducer.data[`${tableType}Default`];
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_TEMPLATE_BY_ID,
      value: defaultTemplate,
      key: "data",
    });
  };
};

export const emptyOutTemplateState = (templateToUpdate, initialState) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EMPTY_OUT_TEMPLATE_STATE,
      value: initialState,
      key: templateToUpdate,
    });
  };
};
export const exportAssociatedNumbersCsv = (identityId, templateId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EXPORT_ASSOCIATED_NUMBERS_LOADING,
      value: true,
    });
    TemplateApiClient.exportAssociatedNumbersCsv(identityId, templateId)
      .then((res) => {
        fileSaver.saveAs(
          res.data,
          `sts-template-numeri-associati-${new Date().toISOString()}.csv`
        );
      })
      .catch((err) => {
        console.error(err.response.data);
        toastError("Errore durante il download del file CSV");
      })
      .finally(() => {
        dispatch({
          type: actionTypes.EXPORT_ASSOCIATED_NUMBERS_LOADING,
          value: false,
        });
      });
  };
};

export const getAssociatoLabel = (identityId) => {
  const voip = store.getState().templateReducer.data[`voip`];
  const wlr = store.getState().templateReducer.data[`wlr`];
  const standard = store.getState().templateReducer.data[`standard`];
  return (dispatch) => {
    dispatch({
      type: actionTypes.ASSOCIATO_LABEL_LOADING,
      value: true,
    });
    TemplateApiClient.fetchAssociatoLabel(identityId)
      .then((res) => {
        const mergedVoip = voip.map((v) => ({
          ...v,
          ...res.data.result.voip.find((associateV) => associateV.id === v.id),
        }));
        const mergedWlr = wlr.map((w) => ({
          ...w,
          ...res.data.result.wlr.find((associateW) => associateW.id === w.id),
        }));
        const mergedStandard = standard.map((s) => ({
          ...s,
          ...res.data.result.standard.find(
            (associateS) => associateS.id === s.id
          ),
        }));
        dispatch({
          type: actionTypes.ASSOCIATO_LABEL_DATA,
          voip: mergedVoip,
          wlr: mergedWlr,
          standard: mergedStandard,
        });
      })
      .catch((err) => {
        console.error(err.response.data);
      })
      .finally(() => {
        dispatch({
          type: actionTypes.ASSOCIATO_LABEL_LOADING,
          value: false,
        });
      });
  };
};
