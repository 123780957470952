import {getAxiosClient} from "../config/axios";

const CustomersApiClient = {
  fetchClientsList: (
    page,
    size,
    sortBy,
    sortOrder,
    searchText,
    integrationId
  ) => {
    return getAxiosClient().get(`/api/v1/pga/customers`, {
      params: {
        page,
        size,
        sortBy,
        sortOrder,
        searchText,
        integrationId,
      },
    });
  },
  fetchCompanyName: (integrationId) => {
    return getAxiosClient().get(`/api/v1/pga/customers/${integrationId}/name`);
  },
};

export default CustomersApiClient;
