import { ScaleLoader } from "react-spinners";
import PropTypes from "prop-types";
import { TableCell, TableRow, Typography, useTheme } from "@mui/material";

const TableLoader = (props) => {
  const theme = useTheme();

  return (
    <TableRow>
      <TableCell
        colSpan={props.colSpan}
        align="center"
        sx={{ "& > span": { display: "initial !important" } }}
      >
        <ScaleLoader color={theme.palette.primary.main} />
        <Typography color={"primary"} variant={"subtitle1"}>
          Caricamento in corso
        </Typography>
      </TableCell>
    </TableRow>
  );
};
TableLoader.propTypes = {
  colSpan: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TableLoader;
