import {useState} from 'react';
import CustomToolbar from "./toolbar/CustomToolbar";
import Sidebar from "./sidebar/Sidebar";


const Navigation = ({children}) => {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const handleSidebarToggle = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <>
            <CustomToolbar handleSidebarToggle={handleSidebarToggle}/>
            <Sidebar sidebarOpen={sidebarOpen} handleSidebarToggle={handleSidebarToggle}/>
            {children}
        </>
    );
}

export default Navigation;
