import Keycloak from "keycloak-js";

const getKeycloakConfigFile = () => {
  const host = window.location.hostname;
  if (host.includes("localhost")) return "/keycloak-dev.json";
  else if (host.includes("operator-stsenterprise-int.fastbee.systems"))
    return "/keycloak-int-operator.json";
  else if (host.includes("stsenterprise-int.fastbee.systems"))
    return "/keycloak-int-customer.json";
  else if (host.includes("operator-stsenterprise.fastweb.it"))
    return "/keycloak-prod-operator.json";
  else if (host.includes("stsenterprise.fastweb.it"))
    return "/keycloak-prod-customer.json";
  return "null";
};

const _kc = new Keycloak(process.env.PUBLIC_URL + getKeycloakConfigFile());

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      promiseType: "native",
      onLoad: "check-sso",
      flow: "standard",
      checkLoginIframe: false,
      redirectUri: process.env.PUBLIC_URL,
    })
    .then((authenticated) => {
      if (authenticated) {
        onAuthenticatedCallback();
      } else {
        doLogin();
      }
    });
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const getTokenParsed = _kc.tokenParsed;

const getInstance = _kc;

const hasResourceRole = (roles, resource) =>
  roles.some((role) => _kc.hasResourceRole(role, resource));

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  hasResourceRole,
  getTokenParsed,
  getInstance,
};

export default KeycloakService;
