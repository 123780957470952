export const initialCreateGroupState = {
  data: {},
  loading: false,
};

export const initialNumeriDetails = {
  data: {
    number: "",
    numberId: "",
    range: "",
    sede: "",
    tags: "",
    template: "",
    tipoLinea: "",
    isRangeVisible: false,
  },
  loading: false,
};

export const initialTagsForNumbers = {
  data: {
    identityId: "",
    numbers: [],
    fullyAssociated: [],
    partiallyAssociated: [],
  },
  loading: false,
};
