import GruppiApiClient from "../../api/gruppiApiClient";
import * as actionTypes from "./actionTypes";
import { store } from "../store";
import { toastError, toastSuccess } from "../../component/shared/toast/Toast";
import NumeriApiClient from "../../api/numeriApiClient";

export const getGruppiTableData = (identityId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_GRUPPI_DATA_AND_LOADING,
      value: true,
      key: "loading",
    });
    GruppiApiClient.getGruppiTableData(identityId)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_GRUPPI_DATA_AND_LOADING,
          value: res.data,
          key: "data",
        });
      })
      .catch((e) => {
        console.error(e.response.data);
        dispatch({
          type: actionTypes.GET_GRUPPI_DATA_AND_LOADING,
          value: {
            voip: [],
            wlr: [],
            standard: [],
          },
          key: "data",
        });
      })
      .finally(() => {
        dispatch({
          type: actionTypes.GET_GRUPPI_DATA_AND_LOADING,
          value: false,
          key: "loading",
        });
      });
  };
};

export const cancelaGruppo = (
  groupId,
  handleDialogClose,
  dtoBody,
  setSelected,
  closeModify
) => {
  return (dispatch) => {
    const identityId =
      store.getState().customersReducer.selectedCustomer.integrationId;
    GruppiApiClient.cancelaGruppo(dtoBody)
      .then((res) => {
        if (res.data.statusType !== "OK") {
          toastError("Operazione fallita.");
        } else {
          toastSuccess("Gruppo eliminato con successo.");
          handleDialogClose();
          dispatch(getGruppiTableData(identityId));
          setSelected([]);
          closeModify(false);
        }
      })
      .catch((e) => {
        console.log(e.response.data);
        toastError("Operazione fallita.");
      });
  };
};

export const getNumbersForGroup = (identityId, tecnologia, groupId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_NUMBERS_FOR_GROUP,
      value: true,
      key: "loading",
    });
    NumeriApiClient.getNumbersForGroup(identityId, tecnologia, groupId)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_NUMBERS_FOR_GROUP,
          value: res.data.result,
          key: "data",
        });
      })
      .catch((e) => console.error(e.response.data))
      .finally(() => {
        dispatch({
          type: actionTypes.GET_NUMBERS_FOR_GROUP,
          value: false,
          key: "loading",
        });
      });
  };
};

export const modifyGroup = (requestBody) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.MODIFY_GROUP,
      value: true,
      key: "loading",
    });
    NumeriApiClient.modifyGroup(requestBody)
      .then((res) => {
        dispatch({
          type: actionTypes.MODIFY_GROUP,
          value: res.data,
          key: "data",
        });
        if (res.data.statusType !== "OK") {
          toastError(
            "Si è verificato un errore durante la modifica del gruppo."
          );
        }
      })
      .catch((e) => {
        toastError("Operazione fallita.");
        console.error(e.response.data);
      })
      .finally(() => {
        dispatch({
          type: actionTypes.MODIFY_GROUP,
          value: false,
          key: "loading",
        });
      });
  };
};

export const clearModifyGroupState = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.MODIFY_GROUP,
      value: {},
      key: "data",
    });
  };
};
