export const headCells = [
  { id: "checkmark", label: "", minWidth: 0, align: "left" },
  {
    id: "codiceCliente",
    label: "Codice Cliente",
    minWidth: 150,
    align: "left",
  },
  {
    id: "regioneSociale",
    label: "Ragione sociale",
    minWidth: 200,
    align: "left",
  },
  {
    id: "pIva",
    label: "P.IVA",
    minWidth: 100,
    align: "left",
  },
  {
    id: "codiceFiscale",
    label: "C.F.",
    minWidth: 100,
    align: "left",
  },
];
