import {
  applyMiddleware,
  combineReducers,
  compose,
  legacy_createStore as createStore,
} from "redux";
import thunk from "redux-thunk";
import gruppiReducer from "./gruppi/reducer";
import templateReducer from "./template/reducer";
import statoRichiesteReducer from "./stato-richieste/reducer";
import numeriReducer from "./numeri/reducer";
import customersReducer from "./customers/reducer";
import sediReducer from "./sedi/reducer";
import tagManagerReducer from "./tag-manager/reducer";

const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

const rootReducer = combineReducers({
  gruppiReducer,
  templateReducer,
  statoRichiesteReducer,
  numeriReducer,
  customersReducer,
  sediReducer,
  tagManagerReducer,
});

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
