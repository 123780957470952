import * as actionTypes from "../sedi/actionTypes";
import { sediDetailsInitialState, sediInitialState } from "./helper";
import { updateObject } from "../../util/updateObject";
const initialState = {
  sediTableData: sediInitialState,
  sediDetails: sediDetailsInitialState,
  csvLoading: false,
};

const sediTableData = (state, action) => {
  return updateObject(state, {
    sediTableData: updateObject(state.sediTableData, {
      [action.key]: action.value,
    }),
  });
};

const setSediDetails = (state, action) => {
  return updateObject(state, {
    sediDetails: updateObject(state.sediDetails, {
      [action.key]: action.value,
    }),
  });
};

const setExportCsvLoading = (state, action) => {
  return updateObject(state, {
    csvLoading: action.value,
  });
};

const sediReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SEDI_DATA_AND_LOADING:
      return sediTableData(state, action);
    case actionTypes.GET_SEDI_DETAILS_AND_LOADING:
      return setSediDetails(state, action);
    case actionTypes.EXPORT_SEDI_LOADING:
      return setExportCsvLoading(state, action);
    default:
      return state;
  }
};

export default sediReducer;
