import * as actionTypes from "./actionTypes";
import { updateObject } from "../../util/updateObject";

const initialState = {
  data: [],
  loading: false,
  statoRichiesteDetails: {
    data: [],
    loading: false,
  },
  csvLoading: false,
};

const setStatoRichiesteTablesData = (state, action) => {
  return updateObject(state, {
    [action.key]: action.value,
  });
};
const setStatoRichiesteDetails = (state, action) => {
  return updateObject(state, {
    statoRichiesteDetails: updateObject(state.statoRichiesteDetails, {
      [action.key]: action.value,
    }),
  });
};

const setExportCsvLoading = (state, action) => {
  return updateObject(state, {
    csvLoading: action.value,
  });
};

const statoRichiesteReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STATO_RICHIESTE_DATA_AND_LOADING:
      return setStatoRichiesteTablesData(state, action);
    case actionTypes.GET_STATO_RICHIESTE_DETAILS:
      return setStatoRichiesteDetails(state, action);
    case actionTypes.EXPORT_STATO_RICHIESTE:
      return setExportCsvLoading(state, action);
    default:
      return state;
  }
};

export default statoRichiesteReducer;
