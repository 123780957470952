import { Radio, TableCell, TableRow } from "@mui/material";
import EmptyRow from "../../../../ui/tables/table-rows/empty-row/EmptyRow";
import TableWithLabels from "../../../../ui/tables/table-with-labels/TableWithLabels";
import { useSelector } from "react-redux";
import { headCells } from "./CustomersTableHelper";

const CustomersTable = (props) => {
  const companies = useSelector(
    (state) => state.customersReducer.customersList.data.customers
  );
  const count = useSelector(
    (state) => state.customersReducer.customersList.data.totalElements
  );
  const loading = useSelector(
    (state) => state.customersReducer.customersList.loading
  );

  const clickTableRowCustomers = (row) => {
    props.setSelectedCustomer(row);
    props.setIsApplicaDisabled(false);
  };

  const tableRows =
    count !== 0 ? (
      companies.map((row, index) => {
        return (
          <TableRow
            hover
            onClick={() => clickTableRowCustomers(row)}
            key={"customer-table-" + index}
            sx={{ cursor: "pointer" }}
          >
            <TableCell align="left" style={{ padding: "0 0 0 0.75em" }}>
              <Radio
                checked={
                  props.selectedCustomer.integrationId === row.integrationId
                }
                onChange={() => props.setSelectedCustomer(row)}
                value={row.integrationId}
                name="selectedCustomer"
              />
            </TableCell>
            <TableCell align="left">{row.integrationId}</TableCell>
            <TableCell align="left">{row.name}</TableCell>
            <TableCell align="left">{row.pIva}</TableCell>
            <TableCell align="left">{row.codiceFiscale}</TableCell>
          </TableRow>
        );
      })
    ) : (
      <EmptyRow text="dato" colSpan={headCells.length} />
    );

  return (
    <TableWithLabels
      headCells={headCells}
      tableRows={tableRows}
      tableData={companies}
      page={props.page}
      fetchData={props.fetchData}
      rowsPerPage={props.rowsPerPage}
      count={count}
      loading={loading}
    />
  );
};

export default CustomersTable;
