import * as actionTypes from "./actionTypes";
import { updateObject } from "../../util/updateObject";
import { cancelaGruppo } from "./actions";
import { initialModifyGroupState } from "./helper";

const initialState = {
  data: {
    voip: [],
    standard: [],
    wlr: [],
  },
  loading: false,
  sims: {
    data: {
      associatedNumbers: [],
      notAssociatedNumbers: [],
    },
    loading: false,
  },
  modifyGroup: initialModifyGroupState,
};

const setGruppiTablesData = (state, action) => {
  return updateObject(state, {
    [action.key]: action.value,
  });
};

const setNumbersForGroup = (state, action) => {
  return updateObject(state, {
    sims: updateObject(state.sims, {
      [action.key]: action.value,
    }),
  });
};

const setModifyGroupStatus = (state, action) => {
  return updateObject(state, {
    modifyGroup: updateObject(state.modifyGroup, {
      [action.key]: action.value,
    }),
  });
};

const gruppiReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_GRUPPI_DATA_AND_LOADING:
      return setGruppiTablesData(state, action);
    case actionTypes.CANCELA_GRUPPO_AND_LOADING:
      return cancelaGruppo(state, action);
    case actionTypes.GET_NUMBERS_FOR_GROUP:
      return setNumbersForGroup(state, action);
    case actionTypes.MODIFY_GROUP:
      return setModifyGroupStatus(state, action);
    default:
      return state;
  }
};

export default gruppiReducer;
