import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

const toastMessage = (message) => {
  return (
    <Box display="inline-flex" alignItems="center" pt="5px">
      <ErrorIcon />
      <Box ml="1em" whiteSpace="pre-wrap">
        {message}
      </Box>
    </Box>
  );
};

export const toastSuccess = (message) => {
  toast.success(toastMessage(message), {
    position: toast.POSITION.BOTTOM_CENTER,
    draggable: false,
  });
};

export const toastError = (message) => {
  toast.error(toastMessage(message), {
    position: toast.POSITION.BOTTOM_CENTER,
    draggable: false,
  });
};
