export const routePaths = {
  STS: "/sts",
  NOT_PERMITTED: "/not-permitted",
  HOME: "/",
};

// export const integrationId = "0692486";

export const SELECTED_CUSTOMER = "SELECTED_CUSTOMER";

// export const tagConstants = {
//   OBJECT_CATEGORY_NAME: "PERFMON",
//   CLIENT_NAME: "PGA",
// };

export const OPERATOR = "Operator";
